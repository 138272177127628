#grnhse_app {
  width: 100%;
}

.job-details {
  color: #010911;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
}

@media screen and (min-width: 640px) {
  .job-details {
    font-size: 16px;
    line-height: 24px;
  }
}

.job-details p > strong:only-child {
  color: #09268a;
  font-family: 'Raleway';
  font-size: 18px;
  font-style: normal;
  font-weight: 700;
  line-height: 28px;
  padding-top: 24px;
  padding-bottom: 8px;
  display: inline-block;
}

@media screen and (min-width: 640px) {
  .job-details p > strong:only-child {
    font-size: 20px;
    padding-top: 24px;
    padding-bottom: 16px;
  }
}

@media screen and (min-width: 1024px) {
  .job-details p > strong:only-child {
    font-size: 20px;
    padding-top: 40px;
    padding-bottom: 16px;
  }
}

.job-details p > strong:not(:only-child) {
  font-weight: 700;
}

.job-details ul {
  padding-left: 20px;
}

.job-details ul li {
  list-style: disc;
  padding-left: 10px;
}

.job-details ul li:not(:last-child) {
  padding-bottom: 8px;
}

.job-details a {
  color: #0b2fac;
  text-align: center;
  font-family: Work Sans;
  font-style: normal;
  font-weight: 500;
  text-decoration-line: underline;
}

.job-details a::after {
  content: '';
}
