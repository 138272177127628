.slider-container {
  width: 100%;
}
.slider-list {
  align-items: center;
}
.slide {
  opacity: 0.5 !important;
  transition: all 0.3s linear;
  padding: 0 4px !important;
}
.slide.slide-current {
  opacity: 1 !important;
  transition: all 0.3s linear;
}
.slide .card {
  margin: 24px 0;
  padding: 40px !important;
}
.slide.slide-current .card {
  transition: all 0.05s linear;
  padding: 40px 16px !important;
  margin: 0;
}

@media screen and (min-width: 1025px) {
  .slide.slide-current .card {
    padding: 64px 40px !important;
  }
  .slide {
    padding: 0 16px !important;
  }
}

@media screen and (min-width: 640px) and (max-width: 1024px) {
  .slide.slide-current .card {
    padding: 56px 24px !important;
  }
  .slide {
    padding: 0 8px !important;
  }
} ;
