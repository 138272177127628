:root {
  --bg-mobile: none;
  --bg-tablet: none;
  --bg-desktop: none;
}

.bg-image-set {
  background-image: var(--bg-mobile);
}

@media screen and (min-width: 640px) {
  .bg-image-set {
    background-image: var(--bg-tablet);
  }
}

@media screen and (min-width: 1024px) {
  .bg-image-set {
    background-image: var(--bg-desktop);
  }
}
